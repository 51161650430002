.banner .d-flex {
  justify-content: end;
}

.philosophy .d-flex {
  justify-content: center;
  gap: 5%;
  align-items: flex-start;
}

.title_sub_title {
  text-align: end;
  width: 80%;
  display: block;
  margin-top: 50px;
}

.philosophy p {
  text-align: left;
  padding: 5% 0;
  font-family: "Merriweather", Sans-serif;
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 1.6em;
}

/* Adjust the background position and size */
.philosophy-program .basicComponent {
  background-position: center bottom;
  background-size: cover;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 100px 0;
  /* Simplify padding values */
  min-height: 800px;
  position: relative;
}

/* Center and space the content */
.philosophy-program .basicComponent_wrapper {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  /* Center the content horizontally */
  padding: 3em;
  position: relative;
  gap: 5%;
  height: 450px;
}

/* Style the heading */
.philosophy-program .basicComponent_wrapper h2 {
  color: #f37042;
  font-family: "Lato", sans-serif;
  /* Use lowercase for font family name */
  font-size: 3.8rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1em;
  text-align: center;
  letter-spacing: 3.8px;
  margin: 0;
  /* Remove default margin */
}

/* Style the paragraph text */
.philosophy-program .basicComponent_wrapper p {
  text-align: left;
  /* Align text to the left */
  font-family: "Merriweather", sans-serif;
  /* Use lowercase for font family name */
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 1.6em;
  padding: 20px 0;
}

/* Style the background overlay */
.philosophy-program .elementor-background-overlay {
  background-color: #00a8782b;
  opacity: 0.47;
  mix-blend-mode: darken;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  position: absolute;
  top: 0;
  left: 0;
  /* Set the left position */
  width: 100%;
  height: 100%;
}

/* Style the additional background overlay */
.philosophy-program .background-overlay {
  background-color: rgba(255, 255, 255, 0.38);
  opacity: 0.55;
}

/* Adjust content alignment */
.philosophy-program .basicComponent_content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /* Align content to the right */
  gap: 4%;
  position: relative;
  z-index: 1;
}

/* Director Section */
section.director {
  padding: 70px 0px;
  background: #fff;
}

.director h2 {
  color: #00a878;
  font-family: "Lato", sans-serif;
  font-size: 60px;
  font-weight: 600;
  padding-bottom: 30px;
}

.director h3 {
  color: #f37042;
  font-family: "Merriweather", sans-serif;
  font-size: 33px;
  font-weight: 300;
  padding-bottom: 20px;
}

.director .basicComponent_wrapper {
  display: flex;
  justify-content: space-between;
  margin: auto;
}

.director .basicComponent_content p {
  padding: 15px 0px;
  font-family: "Merriweather", sans-serif;
  text-align: justify;
  font-size: 16px;
  font-weight: 300;
}

.director .basicComponent_content {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: auto;
  width: 50%;
  margin-top: 15px;
}

.director ul {
  list-style: none;
}

.director li {
  padding-left: 30px;
  position: relative;
}

.director li ::before {
  font-family: FontAwesome;
  content: "\f105";
  color: #00a878;
  font-size: 35px;
  position: absolute;
  left: 0;
  top: 14%;
  font-weight: 600;
}

.director .right_img {
  width: 45%;
}

.director .right_img img {
  max-width: 100%;
  width: 487px;
  height: auto;
  margin: auto;
  box-shadow: -12px 12px 57px 10px rgba(16, 45, 94, 0.17);
  border-radius: 3px;
}

.vc_sep_line {
  height: 1px;
  border-top: 2px solid #ebebeb;
  border-color: #f3704280;
  display: block;
  width: 10%;
}

/* for about us page  */
section.TitleDescriptionSection {
  padding-top: 100px;
  margin-top: 50px;
}

section.TitleDescriptionSection .basicComponent {
  background-color: #00a8782b;
  padding: 50px 0;
}

section.TitleDescriptionSection h2 {
  text-align: center;
  color: #f37042;
  font-family: "Lato", Sans-serif;
  font-size: 32px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1em;
  letter-spacing: 3.8px;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.TitleDescriptionSection .custom-rich-text-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* Three equal-width columns */
  gap: 20px;
  /* Adjust the gap between columns as needed */
  align-items: center;
  justify-content: center;
  margin: 50px auto;
  width: 100%;
  overflow: hidden;
  /* Optional: Hide overflowing content */
}

.TitleDescriptionSection span {
  width: 24%;
  margin: 0 auto;
  display: block;
  height: 1px;
  border-top: 1px solid #f37042;
}

.TitleDescriptionSection .custom-rich-text-container p {
  text-align: justify;
  font-family: "Merriweather", Sans-serif;
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 1.6em;
}

.TitleDescriptionSection .custom-rich-text-block {
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.TitleDescriptionSection .custom-rich-text-container p:empty {
  display: none;
}

/* for about-us page director  */

section.director h2 {
  color: #00a878;
  font-family: "Lato", sans-serif;
  font-size: 60px;
  font-weight: 600;
  padding-bottom: 30px;
}

section.director h3 {
  color: #f37042;
  font-family: "Merriweather", sans-serif;
  font-size: 33px;
  font-weight: 300;
  padding-bottom: 20px;
}

section.director ul {
  list-style: none;
}

section.director li {
  padding-left: 30px;
  position: relative;
}

section.director li ::before {
  font-family: FontAwesome;
  content: "\f105";
  color: #00a878;
  font-size: 35px;
  position: absolute;
  left: 0;
  top: 20%;
  font-weight: 600;
}

section.director .custom-rich-text-container li p {
  padding: 7px 5px;
  font-family: "Merriweather", sans-serif;
  text-align: justify;
  font-size: 16px;
  font-weight: 300;
  width: 100%;
}

section.director .custom-rich-text-container ul:nth-of-type(2) {
  width: 85%;
  list-style: disc;
  font-size: 30px;
  padding-left: 30px;
}

section.director .custom-rich-text-container ul:nth-of-type(2) li {
  padding-left: 0px;
}

section.director .custom-rich-text-container ul:nth-of-type(2) p::before {
  content: none;
}

section.director .custom-rich-text-container ul:nth-of-type(2) p {
  font-size: 19px;
}

section.director .custom-rich-text-container {
  display: flex;
  flex-wrap: wrap;
  margin: auto;
}

section.director .custom-rich-text-container p {
  width: 36%;
  font-family: "Merriweather", Sans-serif;
  font-size: 19px;
  font-weight: 300;
  line-height: 1.6em;
  margin: auto;
  text-align: justify;
  color: #333;
}

section.director .custom-rich-text-container p:empty {
  display: none;
}

.vc_sep_line {
  height: 1px;
  border-top: 2px solid #ebebeb;
  border-color: #f37042;
  display: block;
  width: 10%;
}

/* for program page */
section.program {
  padding: 100px 0px;
  margin: 50px 0px;
}

section.program .title_subtitle {
  text-align: center;
}

section.program .vc_sep_line {
  width: 30%;
  margin: auto;
}

section.program .title_subtitle h2 {
  color: #f37042;
  font-family: "Lato", Sans-serif;
  font-size: 3.8rem;
  font-weight: 600;
  margin-bottom: 30px;
  text-transform: uppercase;
  line-height: 1em;
  letter-spacing: 3.8px;
}

section.program .title_subtitle p {
  text-align: center;
  color: #00a878;
  font-family: "Merriweather", Sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  line-height: 1.6em;
  margin-top: 30px;
}

section.program .d-flex {
  justify-content: space-between;
  gap: 2%;
}

section.program .custom-rich-text-container {
  margin-top: 50px;
}

section.program .d-flex p {
  text-align: justify;
  font-family: "Merriweather", Sans-serif;
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 1.6em;
}

section.Milestones {
  padding-top: 0px;
  margin-top: 0px;
}

section.Milestones .basicComponent {
  background-color: #00a8782b;
  padding: 50px 0;
}

.Milestones .custom-rich-text-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  align-items: center;
  justify-content: center;
  margin: 50px auto;
  width: 100%;
  overflow: hidden;
}
.Milestones .title_subtitle {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  width: 60%;
}
.Milestones .title_subtitle p {
  color: #f37042;
  font-family: "Proza Libre", Sans-serif;
  font-size: 1rem;
  font-weight: 100;
  text-transform: uppercase;
  line-height: 1em;
  letter-spacing: 1px;
}

.Milestones .title_subtitle h2 {
  color: #00a878;
  font-family: "Merriweather", Sans-serif;
  font-size: 3rem;
  font-weight: 400;
  line-height: 1em;
  letter-spacing: 1px;
  width: 19%;
  padding: 20px 0px;
}
.Milestones .vc_sep_line {
  height: 1px;
  border-top: 4px solid #ebebeb;
  border-color: #f37042;
  display: block;
  width: 7%;
  position: relative;
  left: 16%;
}

.Milestones .custom-rich-text-subheading h2 {
  color: #f37042;
  font-family: "Lato", Sans-serif;
  font-size: 2rem;
  font-weight: 800;
  text-transform: uppercase;
  font-style: normal;
  line-height: 2.1em;
  letter-spacing: 3.8px;
  -webkit-text-stroke-color: #000;
  stroke: #000;
  width: 60%;
  text-align: center;
  left: 0%;
  margin: 0%;
}
.Milestones .custom-rich-text-container p {
  text-align: left;
  font-family: "Merriweather", Sans-serif;
  font-size: 1.1rem;
  font-weight: 300;
  line-height: 2em;
}
.Milestones .custom-rich-text-container ul {
  font-size: 22px;
}
.Milestones .custom-rich-text-container li ul {
  list-style: square;
  width: 40%;
  margin: auto;
}

.program-details .custom-rich-text-subheading h2 {
  color: #00a878;
  font-family: "Merriweather", Sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 2.1em;
  letter-spacing: 3.8px;
  text-align: center;
  width: 100%;
  position: unset;
}

.program-details .basicComponent_content h5 {
  color: #f37042;
  font-family: "Karla", Sans-serif;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 26px;
  letter-spacing: 1.3px;
  text-align: center;
}
.program-details .custom-rich-text-block img {
  width: 85px;
  margin: auto;
  display: flex;
}
.program-details .custom-rich-text-block {
  background: none;
}
.program-details .basicComponent_content .custom-rich-text-block .vc_sep_line {
  height: 1px;
  border-top: 2px solid #ebebeb;
  border-color: #f37042;
  display: block;
  width: 6%;
  margin: auto;
}
.banner .custom-rich-text-block .vc_sep_line {
  display: none;
}
.collapsible {
  padding: 20px 0px;
  text-align: center;
}

.collapsible .button {
  font-size: 20px;
  /* border: 0; */
  box-sizing: border-box;
  border: none;
  padding: 12px 12px;
  background-color: #f37042;
  border-radius: 3px;
  margin: auto;
  color: #fff;
  width: 55%;
}
.collapsible .button span {
  margin-left: 35px;
  /* margin-top: 8px; */
  font-size: 25px;
}
.collapsible .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.collapsible .Content {
  text-align: justify;
  font-family: "Merriweather", Sans-serif;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 2em;
  color: #f37042;
  background: #fff;
}
.collaps-program {
  padding: 50px 0;
  background-color: rgba(231, 10, 10, 0.04);
}
.collapsible .button {
  margin: 5px 0px;
  border: 1px solid;
  cursor: pointer;
}
.activ.collapsible,
.collapsible .button:hover {
  background: #00a878;
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  transition-duration: 0.5s;
}
.collapsible .Content {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  padding: 20px;
  /* color: #000; */
  border: 1px solid #ebebeb;
  margin: auto;
  width: 55%;
}
.collapsible-bg {
  background-color: rgba(231, 10, 10, 0.04);
  padding: 70px 0px;
}
.button.expanded {
  /* Styles for the expanded button */
  background-color: #f37042;
}

.Content.expanded-content {
  /* Styles for the expanded content */
  background: #00a878;
}
.Home-about .home-basic .basicComponent .basicComponent_content {
  background-color: unset;

  width: 900px;
  top: 13%;
  left: 18.5%;
}
section.philosophy {
  margin-top: 0px;
}
.Content {
  max-height: 500px; /* Set a reasonable max-height or adjust as needed */
  overflow: hidden;
  transition: max-height 0.3s ease;
}
@media (max-width: 820px), (max-width: 1024px) {
  section.director .custom-rich-text-container p {
    width: 100%;
    padding: 9px;
  }
}

@media (max-width: 768px) {
  .title_sub_title {
    width: 100%;
    text-align: center;
  }

  .philosophy-program .basicComponent_wrapper {
    flex-direction: column;
    height: 100%;
    padding: 20px;
  }

  .philosophy-program .basicComponent_wrapper h2 {
    height: 100%;
  }

  .philosophy-program .elementor-background-overlay {
    width: 100%;
    height: 100%;
  }

  .philosophy-program .basicComponent_wrapper h2 {
    font-size: 20px;
    padding-bottom: 100px;
  }

  .philosophy-program .basicComponent {
    padding: 0px;
    height: 100%;
  }

  .philosophy-program .basicComponent_content {
    align-items: center;
    padding-top: 100px;
  }

  .director .basicComponent_wrapper {
    flex-direction: column;
  }

  .director .basicComponent_content {
    flex-direction: column;
  }

  .director .right_img {
    width: 70%;
    margin: auto;
    padding-top: 40px;
  }

  .director .right_img img {
    height: auto;
  }

  .director h2 {
    text-align: center;
    font-size: 45px;
  }

  .director h3 {
    padding-left: 20px;
  }

  .vc_sep_line {
    margin-left: 20px;
  }

  .director h3 {
    padding-left: 30px;
  }

  section.director .custom-rich-text-container ul:nth-of-type(2),
  section.director .custom-rich-text-container p {
    width: 100%;
  }

  section.director ul {
    padding-bottom: 20px;
  }

  section.director h3 {
    padding-left: 0px;
    padding-top: 15px;
  }
  section.program .title_subtitle h2 {
    font-size: 2.8rem;
  }
  section.program .d-flex {
    flex-direction: column;
  }
  .Milestones .title_subtitle {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
  }
  .Milestones .title_subtitle h2 {
    width: 100%;
  }
  .Milestones .vc_sep_line {
    left: 27%;
    width: 10%;
  }
  .Milestones .custom-rich-text-container {
    grid-template-columns: repeat(1, 0.8fr);
  }
  .Milestones .custom-rich-text-subheading h2 {
    width: 100%;
  }
  .collapsible .button {
    width: 100%;
    font-size: 14px;
    padding: 10px;
  }
  .collapsible .button span {
    margin-top: 3px;
  }
  .collapsible .Content {
    width: 100%;
  }
}
@media (max-width: 320px) {
  .Milestones .vc_sep_line {
    left: 70%;
    width: 10%;
  }
}
