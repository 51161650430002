@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,400&display=swap");

/* Reset and general styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  max-width: 100%;
  width: 90%;
  margin: auto;
}

.wrapper {
  padding-top: 100px;
}

/* Header styles */
header {
  background-color: #fff;
  position: fixed;
  width: 100%;
  height: 100px;
  z-index: 2;
}

.d-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
}

.logo img {
  width:120px;
  height: 100%;
  /* Adjust the width as needed */
}

.humber_menu {
  display: none;
  /* Hide the menu icon on larger screens */
  cursor: pointer;
}

nav ul {
  list-style: none;
  display: flex;
}

nav ul li {
  display: inline;
  margin-right: 20px;
}

nav ul li a {
  font-size: 18px;
  margin: 0 10px;
  font-weight: 500;
  color: #f37042;
  text-decoration: none;
}

nav li a.active {
  color: #00a878;
  border-bottom: 1px solid #f37042;
}

nav ul li a:hover {
  color: #00a878;
  transition-duration: 0.5s;
  border-bottom: 1px solid #f37042;
}

.banner {
  background-position: top center;
  position: relative;
  background-size: cover;
  height: 100vh;
  /* transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s; */
}
.banner .homepage_banner .custom-rich-text-block {
  background-color: #e5d8d86b;
  width: 100%;
  margin: 0px;
}

.banner .homepage_banner img {
  width: 300px;
  display: block;
  margin: 0px;
}

.banner .homepage_banner .custom-rich-text-container {
   position: relative;
  padding-top: 0px;
}

.homepage_banner .custom-rich-text-heading h1 {
  color: #ffffff;
  font-family: "Lato", Sans-serif;
  font-size: 66px;
  font-weight: 700;
  letter-spacing: -1px;
  padding: 6px 0px 30px 0px;
  width: 100%;
  margin: 0px;
}

.homepage_banner .custom-rich-text-subheading h2 {
  color: #ffffff;
  font-family: "Merriweather", Sans-serif;
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  width: 100%;
  margin: 0px;
  left: 6%;
  
} 


/* for program section banner page */

 .custom-rich-text-block {
  background-color: #e5d8d86b;
  width: 65%;
  margin: auto;
}

.banner img {
  width: 300px;
  display: flex;
  margin: auto;
}

.background-overlay {
  background-color: #49066d;
  opacity: 0.36;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}

.banner .custom-rich-text-container {
  position: relative;
  padding-top: 30px;
}

.custom-rich-text-heading h1 {
  color: #ffffff;
  font-family: "Lato", Sans-serif;
  font-size: 49px;
  font-weight: 700;
  letter-spacing: -1px;
  padding: 6px 0px 30px 0px;
  width: 60%;
  margin: auto;
}

.custom-rich-text-subheading h2 {
  color: #ffffff;
  font-family: "Merriweather", Sans-serif;
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  width: 60%;
  margin: auto;
  position: relative;
  left: 10%;
}


.Home-about .basicComponent {
  padding: 50px 0px;
  height: 500px;
  position: relative;
}

.Home-about .basicComponent .basicComponent_wrapper h2 {
  color: #f37042;
  font-family: "Lato", Sans-serif;
  font-size: 60px;
  font-weight: 600;
  letter-spacing: 3.8px;
  text-align: center;
}
.Home-about .basicComponent .basicComponent_wrapper {
  width: 50%;
  margin: auto;
}

.Home-about .basicComponent .basicComponent_wrapper h3 {
  text-align: center;
  font-weight: 300;
  font-size: 24px;
  color: #00a878;
  font-family: "Merriweather", serif;
  letter-spacing: 3.5px;
}

.Home-about .basicComponent .basicComponent_content {
  background-color: #fff;
  box-shadow: 20px 20px 80px 0px rgba(0, 0, 0, 0.1);
  width: 800px;
  margin: auto;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 22px;
  padding: 50px 50px 50px 50px;
  position: absolute;
  top: 40%;
  left: 21.5%;
}

.Home-about .basicComponent_content p {
  line-height: 1.9;
  font-family: "Merriweather", serif;
  font-size: 19px;
  text-align: justify;
  font-weight: 200;
  padding: 25px;
  color: rgb(51, 51, 51);
  position: relative;
  padding-left: 30px;
}

.cta-button {
  border: none;
  padding: 12px 25px;
  background-color: #f37042;
  border-radius: 35px;
  font-size: 17px;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  transition: transform 0.3s ease-in;
}

.cta-button::after {
  font-family: FontAwesome;
  content: "\f0da";
  color: #fff;
  font-size: 20px;
  position: absolute;
  font-weight: 600;
}

.cta-button:hover {
  background-color: #00a878;
  transform: scale(0.9);
}

.contact_us h2 {
  color: #f37042;
  font-family: "Merriweather", Sans-serif;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
}

.form,
.map {
  width: 40%;
  margin: auto;
}

form .d-flex {
  display: flex;
  align-items: center;
  margin: auto;
  gap: 5%;
}

.input-field {
  margin: 40px 0;
  width: 100%;
}

.input-field span,
.text-area span {
  color: red;
}

.input-field label,
.text-area textarea {
  padding: 0;
  border: none;
  outline: none;
  margin: 11px 0;
  display: block;
}

.frist .last {
  font-size: 14.6px;
  font-weight: 400;
}

.input-field input,
.text-area textarea {
  line-height: 23px;
  height: 35px;
  /* border: none; */
  outline: none;
  padding: 6px 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  width: 100%;
  /* -webkit-box-sizing: border-box !important; */
  box-sizing: border-box !important;
  color: inherit;
  font-weight: bold;
}

.text-area textarea {
  min-height: 146px;
  resize: vertical;
}

.submit_btn {
  cursor: pointer;
  line-height: 23px;
  height: 44px;
  padding: 11px 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  /* -webkit-box-sizing: border-box !important; */
  box-sizing: border-box !important;
  color: #333;
  font-size: 16px;
  background: #eee;
}

.basicpage_main {
  background-color: #fef5f5;
  padding: 50px 0px;
}

.basicPage {
  width: 70%;
  margin: auto;
  padding: 50px 40px;
  background-color: #00a8782b;
  box-shadow: 20px 20px 80px 0px rgba(0, 0, 0, 0.1);
}

.basicPage_wrapper h1 {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 70px;
}

.basicPage_wrapper h2 {
  font-size: 16px;
  font-weight: 700;
}
.basicPage_content ul {
  width: 80%;
  margin: auto;
}
.basicPage_content li p {
  padding: 5px 0px;
}
.basicPage_content p {
  text-align: justify;
  padding: 20px 0px;
  line-height: 23px;
  font-size: 14px;
  font-family: "Merriweather", Sans-serif;
}

/* for philosophy css  */
section.philosophy {
  padding: 70px 0;
  margin-top: 70px;
}

.philosophy .Right_img {
  width: 30%;
}

.philosophy .basicComponent_content {
  width: 34.6%;
  margin-top: 54px;
}

.philosophy .Right_img img {
  width: 100%;
}

.philosophy .heading-title {
  color: #00a878;
  font-family: "Lato", Sans-serif;
  font-size: 3.8em;
  font-weight: 600;
  -webkit-text-stroke-color: #000;
  stroke: #000;
}

.philosophy h3 {
  color: #f37042;
  font-family: "Merriweather", Sans-serif;
  font-size: 0.87em;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 1px;
  -webkit-text-stroke-color: #000;
  stroke: #000;
}
/* Program css */
.what-is-this-program {
  padding: 70px 0px;
  margin-top: 70px;
}
section.what-is-this-program .basicComponent {
  width: 60%;
  margin: auto;
}
section.what-is-this-program .basicComponent h2 {
  color: #f37042;
  font-family: "Lato", Sans-serif;
  font-size: 60px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  width: 60%;
  margin: auto;
  padding-bottom: 20px;
}
section.what-is-this-program ul {
  list-style: none;
  padding: 20px 0px;
}
section.what-is-this-program li p::before {
  font-family: FontAwesome;
  content: "\f00c";
  color: #f37042;
  font-size: 35px;
  font-weight: 600;
}
section.what-is-this-program .custom-rich-text-container li p {
  font-family: "Lato", Sans-serif;
  font-size: 18px;
  color: #00a878;
  padding: 5px 11px;
}
section.what-is-this-program .custom-rich-text-container p {
  font-family: "Merriweather", Sans-serif;
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 1.6em;
}
section.what-is-this-program
  .custom-rich-text-container
  .custom-rich-text-subheading
  h2 {
  color: #f37042;
  font-family: "Merriweather", Sans-serif;
  font-size: 17px;
  font-weight: 400;
  font-style: italic;
  line-height: 1.4rem;
  width: 100%;
  left: 0%;
}
section.what-is-this-program .divider-separator {
  height: 1px;
  border-top: 4px solid #ebebeb;
  border-color: #00a878;
  display: block;
  width: 8%;
  margin: auto;
  padding-bottom: 50px;
}
/* stay connected css */
.stay-connected .basicComponent {
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 80px 0px 80px 0px;
  position: relative;
}

.stay-connected .background-overlay {
  background-color: #ffffff;
  opacity: 0.97;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.stay-connected .container {
  position: relative;
}

.stay-connected .heading-title {
  color: #f37042;
  font-family: "Lato", Sans-serif;
  font-size: 3.8rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1em;
  letter-spacing: 3.8px;
  position: relative;
}

.stay-connected .heading-sub-title {
  color: #00a878;
  font-family: "Merriweather", Sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 10px;
  letter-spacing: 1.5px;
}

.stay-connected .heading-title::before {
  content: "";
  position: absolute;
  height: 5px;
  width: 50px;
  bottom: -15px;
  color: #00a878;
  background-color: #00a878;
  margin: auto;
  left: 48%;
}

.stay-connected .title-sub-title {
  text-align: center;
  margin-bottom: 50px;
}

.stay-connected .basicComponent_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  padding: 50px 0px;
}

.stay-connected .basicComponent_content div {
  width: 45%;
  height: 400px;
  /* margin: auto; */
}

.stay-connected .basicComponent_content div img {
  width: 100%;
  height: 100%;
}

.address {
  display: flex;
  align-items: center;
  margin: auto;
  width: 100%;
  gap: 6%;
}

.reach {
  width: 20%;
}

.social {
  width: 25%;
}

.address ul {
  margin-top: 20px;
}

.address p {
  color: #00a878;
  font-family: "Lato", Sans-serif;
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.address li {
  list-style: none;
  display: flex;
  align-items: center;
  margin: auto;
  padding: 8px 0;
  gap: 25px;
}

.address span {
  font-family: "Merriweather", Sans-serif;
  font-size: 0.875rem;
  line-height: 1.5em;
  color: #999999;
}

.address i {
  color: #f37042;
}

/* footer css */

/* .footer {
  padding: 50px 0px;
} */

.footer_title h2 {
  color: #f37042;
  font-family: "Merriweather", Sans-serif;
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
}

.footer .policies {
  max-width: 100%;
  width: 90%;
}

.footer .policies {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 30px;
  margin-bottom: 30px;
}

.footer button {
  box-sizing: border-box;
  border: none;
  padding: 12px 24px;
  background-color: #818a91;
  border-radius: 3px;
  margin: auto;
}

.footer button a {
  color: #ffff;
  font-size: 15px;
  text-decoration: none;
}

/* Mobile menu styles */
@media screen and (min-width:1500px) and (max-width: 1600px){ 
  .Home-about .basicComponent .basicComponent_content {
    left: 25%;
  }
}

@media screen and (max-width: 1450px){ 
  .Home-about .basicComponent .basicComponent_content {
    left: 21.5%;
  }
}
@media screen and (max-width: 1024px) {
  .Home-about .basicComponent {
    height: 800px;
  }
  .Home-about .basicComponent .basicComponent_content {
    left: 11%;
  }
}
@media screen and (max-width: 820px) {
  .Home-about .basicComponent .basicComponent_content {
    left: 1%;
  }
}
@media screen and (max-width: 768px) {
  .footer .policies {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 30px;
    margin-bottom: 30px;
  }
  
}
@media screen and (max-width: 768px), (max-width: 360px) {
  .humber_menu {
    display: block;
    /* Show the menu icon on smaller screens */
  }

  nav {
    position: absolute;
    top: 100px;
    /* Adjust the distance from the top */
    left: 0;
    width: 100%;
    background-color: #fff;
    display: none;
    flex-direction: column;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1;
  }

  nav.open {
    display: flex;
  }

  nav ul {
    flex-direction: column;
    align-items: center;
  }

  nav ul li {
    margin-right: 0;
    margin-bottom: 10px;
  }
  .banner img {
    width: 100%;
  }
  .banner .d-flex {
    flex-direction: column;
  }
  .Home-about .basicComponent {
    height: 100%;
  }
  .Home-about .basicComponent .basicComponent_content {
    width: 100% !important;
    height: 100%;
    margin: auto;
    position: unset;
    padding: 10px 0px;
  }
  .custom-rich-text-heading h1 {
    width: 100%;
    font-size: 35px;
  }
  .custom-rich-text-block {
    width: 100%;
  }
  .custom-rich-text-subheading h2 {
    width: 100%;
    left: 5%;
  }
  .Home-about .basicComponent .basicComponent_wrapper {
    width: 100%;
  }
  .Home-about .basicComponent .basicComponent_wrapper h3 {
    padding: 50px;
  }
  .Home-about .basicComponent .basicComponent_wrapper h2 {
    padding: 20px 15px;
    font-size: 26px;
  }
  .philosophy .d-flex {
    flex-direction: column;
  }
  .philosophy .basicComponent_content {
    text-align: center;
    flex-wrap: wrap;
    display: flex;
    justify-content: flex-start;
  }
  .philosophy .basicComponent_content h4 {
    padding-right: 20px;
  }
  .philosophy .basicComponent_content {
    width: 60%;
  }
  .philosophy .basicComponent_content p {
    padding-left: 50px;
  }
  section.what-is-this-program .basicComponent {
    width: 100%;
  }
  section.what-is-this-program .basicComponent h2 {
    font-size: 32px;
  }
  section.what-is-this-program .custom-rich-text-subheading h2 {
    font-size: 17px;
  }
  .stay-connected .heading-title {
    font-size: 32px;
  }
  .philosophy .Right_img {
    width: 100%;
  }
  .philosophy .Right_img img {
    width: 100%;
    height: 400px;
  }
  section.what-is-this-program
    .custom-rich-text-container
    .custom-rich-text-subheading
    h2 {
    left: 0%;
  }

  .stay-connected .basicComponent_content div,
  .stay-connected .basicComponent_content div img,
  .social,
  .reach {
    width: 100%;
  }
  .stay-connected .basicComponent_content div img {
    padding-top: 20px;
  }
  .stay-connected .basicComponent_content div {
    height: 100%;
  }
  .philosophy .heading-title {
    padding-bottom: 20px;
  }
  .basicPage{
    width: 100%;
  }

  .reach {
    padding-bottom: 20px;
  }
  .form-container .d-flex {
    flex-direction: column;
  }
  .form-container .form {
    width: 100%;
    margin-bottom: 40px;
  }
  .form-container .map {
    width: 100%;
  }

  .stay-connected .basicComponent_content {
    flex-direction: column;
    text-align: center;
  }
  .address {
    flex-direction: column;
    align-items: flex-start;
  }
  .footer_title {
    margin-top: 20px;
  }

  .footer .policies {
    display: block;
    width: 70%;
  }
  .footer button {
    margin: 10px 13px;
  }
}
